import { ETabIndexesOrderingLevels } from '@/shared/app/types';
import { generateCardId } from '../helper_funcs/generateCardId';
import { atomThumbnailIconMarkup } from './thumbnail-icon';

export type TSTStateCalltoaction = {
    text: string;
    size?: 'small' | 'medium';
    styling?: 'filled' | 'outline' | 'text';
    'icon-url'?: string;
    'icon-position'?: 'before' | 'after';
    addClass?: string;
    addToId?: string;
    tabIndex?: number | string;
    override_button_normal_border?: string;
    override_button_normal_background?: string;
    override_button_normal_color?: string;
    override_button_hover_border?: string;
    override_button_hover_background?: string;
    override_button_hover_color?: string;
    override_button_active_color?: string;
    override_button_active_border?: string;
    override_button_active_background?: string;
    override_button_focus_color?: string;
    override_button_focus_border?: string;
    override_button_focus_background?: string;
    override_button_focus_outline?: string;
};

export function atomCallToActionMarkup(state: TSTStateCalltoaction) {
    const text: string = state.text || '';
    const iconStr: string = state?.['icon-url'] || '';
    const iconPos: string = state?.['icon-position'] || '';
    const {
        override_button_normal_border,
        override_button_normal_background,
        override_button_normal_color,
        override_button_hover_border,
        override_button_hover_background,
        override_button_hover_color,
        override_button_active_color,
        override_button_active_border,
        override_button_active_background,
        override_button_focus_color,
        override_button_focus_border,
        override_button_focus_background,
        override_button_focus_outline,
    } = state || {};
    const id = `${generateCardId('calltoaction')}-${state?.addToId || ''}`;
    const isIconBefore: boolean = iconPos === 'before';
    const sizeModClass = state?.size ? `__${state.size}` : '__medium';
    const styleModClass = state?.styling ? `__${state.styling}` : '__filled';
    const horPaddingsModClass = state?.styling !== 'text' ? ' padding-24-left padding-24-right' : '';
    const passedClass = state?.addClass ? ` ${state?.addClass}` : '';
    const icon: string =
        !iconStr || (!iconStr.match(/^http(s)\:\/\//gi) && !iconStr.match(/^data\:/gi))
            ? ''
            : atomThumbnailIconMarkup(
                  iconStr,
                  `${text}-button-icon`,
                  '',
                  undefined,
                  undefined,
                  `margin-${isIconBefore ? 'right' : 'left'}: .5rem;`,
                  true
              );
    const addOverride = (cssVar: string, value: any) => (value ? `${cssVar}: ${value};` : '');
    const tabIndex = String(state?.tabIndex === undefined ? ETabIndexesOrderingLevels.OTHER_AUTO : state.tabIndex || ETabIndexesOrderingLevels.OTHER_AUTO);
    return `
            <style>
                #${id} .button-content {
                    ${addOverride('--button-cta-text-color', override_button_normal_color)}
                    ${addOverride('--button-cta-bcg-color', override_button_normal_background)}
                    ${addOverride('--button-cta-border-color', override_button_normal_border)}
                }
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:hover #${id} .button-content,
                #${id} .button-content:hover {
                    ${addOverride('--button-cta-text-color', override_button_hover_color)}
                    ${addOverride('--button-cta-bcg-color', override_button_hover_background)}
                    ${addOverride('--button-cta-border-color', override_button_hover_border)}
                }
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:active #${id} .button-content,
                #${id} .button-content:active {
                    ${addOverride('--button-cta-text-color', override_button_active_color)}
                    ${addOverride('--button-cta-border-color', override_button_active_border)}
                    ${addOverride('--button-cta-bcg-color', override_button_active_background)}
                }
                .promo-card_card_link:focus .card #${id} .button-content,
                .promo-card_card_link:focus-visible .card #${id} .button-content,
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:focus #${id} .button-content,
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:focus-visible #${id} .button-content,
                #${id} .button-content:focus,
                #${id} .button-content:focus-visible {
                    ${addOverride('--button-cta-text-color', override_button_focus_border)}
                    ${addOverride('--button-cta-bcg-color', override_button_focus_background)}
                    ${addOverride('--button-cta-border-color', override_button_focus_border)}
                    ${addOverride('--button-cta-outline-color', override_button_focus_border)}
                }
            </style>
            <button
                id="${id}"
                role="button"
                onmousedown="setTimeout(()=>this.blur(), 1)"
                onmouseup="this.blur();"
                onclick="this.blur();"
                tabindex="${tabIndex}"
                ${!text ? `aria-label="Clickable button"` : ''}
                class="ark-ui-atom-button-calltoaction-button ${styleModClass}"
            >
                <p class="button-content ${sizeModClass} text-18 padding-8-top padding-8-bottom${horPaddingsModClass}${passedClass}">
                    ${isIconBefore ? icon : ''}
                    ${!text ? '<slot></slot>' : text}
                    ${!isIconBefore ? icon : ''}
                </p>
            </button>
        `;
}
