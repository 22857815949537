/* eslint-disable react-hooks/rules-of-hooks */
import { TCardPropsCMS } from '../../_core/TypesCardPropsCMS';
import { atomThumbnailIconMarkup } from '../../element_atoms/thumbnail-icon';
import { CSS_BREAKPOINT_USED } from '../../global_styles/_breakpoints';
import { getBooleanAttr } from '../../helper_funcs/getBooleanAttr';
import { hexToRgb } from '../../helper_funcs/hexToRgb';
import { atomCallToActionMarkup, TSTStateCalltoaction } from '../../element_atoms/call-to-action';
import { generateCardId } from '../../helper_funcs/generateCardId';

export type TSTState = TCardPropsCMS | any;

export function renderMarkup(passedState: TSTState = null, window = {}) {
    // @ts-ignore
    const _this: any = this;
    const state = passedState;
    const id = generateCardId('ark-ui-block-card-hero-big');
    /* VISUALS */
    const bgColor: string = state?.background_color || `var(--color-white)`;
    const bgImgMobileUrl: string =
        state?.background_image_mobile || state?.background_image_desktop || state?.graphic_592x400;
    const bgImgDesktopUrl: string = state?.background_image_desktop || state?.graphic_592x400;
    const bgCardGradient: string =
        (state?.override_color_overlay
            ? `linear-gradient(180deg,` +
              hexToRgb(state?.override_color_overlay) +
              ` , 0.6) 2.56%, ` +
              hexToRgb(state?.override_color_overlay) +
              `, 0.2) 50.62%, ` +
              hexToRgb(state?.override_color_overlay) +
              `, 0) 100%)`
            : '') ||
        `linear-gradient(180deg, rgba(0, 0, 0, 0.60) 2.56%, rgba(0, 0, 0, 0.20) 50.62%, rgba(0, 0, 0, 0.00) 100%)`;
    /* TOP LEFT TEXTS */
    const topLeftTitle: string = state?.description?.trim?.() || '';
    /* ACTION BAR PARTS */
    const actionBarToggle: boolean = getBooleanAttr('action_bar', { ...state }, _this);
    const actionBarGameIconThumbnail = state?.graphic_56x56 || '';
    const actionBarGameIconAltText = state?.title || state?.game_name || '';
    const actionBarGameTitle: string = state?.title || state?.game_name || '';
    const actionBarGameCategory: string = state?.game_category || '';
    /* BUTTON */
    const btnText: string = state?.link_label || '';
    const btnStateBasic: TSTStateCalltoaction = {
        text: btnText,
        tabIndex: -1,
        size: 'medium',
        override_button_normal_color: state?.override_button_normal_color || '',
        override_button_normal_background: state?.override_button_normal_background || '',
        override_button_normal_border: state?.override_button_normal_border || '',
        override_button_hover_color: state?.override_button_hover_color || '',
        override_button_hover_background: state?.override_button_hover_background || '',
        override_button_hover_border: state?.override_button_hover_border || '',
    };
    /* MODIFICATIONS COMBOS */
    const bgImgMobile: string = bgImgMobileUrl || bgImgDesktopUrl || '';
    const bgImgDesktop: string = bgImgDesktopUrl || bgImgMobileUrl || '';
    const isActionBar = actionBarToggle && actionBarGameTitle;
    const overrideTitleColor = state?.override_color_title || '';
    const overrideCategoryColor = state?.override_color_category || '';
    const overrideDescriptionColor = state?.override_color_description || '';
    const overrideTitleColorStyle = !overrideTitleColor ? '' : ` style="color: ${overrideTitleColor} !important;"`;
    const overrideCategoryColorStyle = !overrideCategoryColor
        ? ''
        : ` style="color: ${overrideCategoryColor} !important;"`;
    const overrideDescriptionColorStyle = !overrideDescriptionColor
        ? ''
        : ` style="color: ${overrideDescriptionColor} !important;"`;
    const useTitleRender = () =>
        `<p aria-label="${actionBarGameTitle}" class="card_action_bar_title fw-medium"${overrideTitleColorStyle}>${actionBarGameTitle}</p>`;
    const useCategoryRender = () =>
        `<p aria-label="${actionBarGameCategory}" class="card_action_bar_subtitle fw-medium"${overrideCategoryColorStyle}>${actionBarGameCategory}</p>`;
    const useDescriptionRender = () =>
        `<div class="card_headline"><p aria-label="${topLeftTitle}" class="card_headline-sub fw-medium"${overrideDescriptionColorStyle}>${topLeftTitle}</p></div>`;
    const renderButtons = () => `
        ${atomCallToActionMarkup(btnStateBasic)}
    `;
    /* OUTPUT */
    const dynamicClasses = `${!isActionBar ? ' __off' : ''}`;
    /* NOTE: skipped badge for now - it will be managed outside */
    const html = `
            <div
                id="${id}"
                class="card padding-8 rounded-8" 
                style="${bgColor ? 'background-color:' + bgColor + ';' : ''}"
                tabindex="-1"
            >
                <picture>
                    <source media="(min-width: ${CSS_BREAKPOINT_USED()}px)" srcset="${bgImgDesktop}">
                    <img
                        class="rounded-8"
                        src="${bgImgMobile}"
                        alt="${actionBarGameTitle}"
                        loading="lazy"
                        style="position: absolute; inset: 0; width: 100%; height: 100%; object-fit: cover; object-position: center;"
                    />
                </picture>
                    <div class="card_overlay rounded-8" style="${
                        bgCardGradient ? 'background: ' + bgCardGradient + ';' : ''
                    }"></div>
                    ${useDescriptionRender()}
                    <div aria-description="Action bar with game title and game category with game play button" class="card_action_bar${dynamicClasses}">
                        ${atomThumbnailIconMarkup(
                            actionBarGameIconThumbnail,
                            'card_action_bar_thumbnail',
                            actionBarGameIconAltText
                        )}
                        <div class="card_action_bar_text">
                            ${useTitleRender()}
                            ${useCategoryRender()}
                        </div>
                        ${btnText && renderButtons()}
                    </div>
            </div>
        `;
    return html;
}
