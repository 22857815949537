import { Badge, ButtonOverride } from '../api/arena-data';

export function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function buildButtonCSSOverrides(isDesktopDetected: boolean, overrides?: ButtonOverride[] | null) {
    const result: { [key: string]: string } = {};
    
    if (!overrides) {
        return result;
    }

    for (const override of overrides ?? []) {
        if (!isDesktopDetected && override.state === 'hover') {
            return {};
        }

        if (override.background) {
            result[`--${override.state}-background`] = override.background;
        }
        if (override.border) {
            result[`--${override.state}-border`] = override.border;
        }
        if (override.color) {
            result[`--${override.state}-color`] = override.color;
        }
    }
    return result;
}

export function getCookie(name:string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
  }

  // Function to get badges for a specific game by slug
  export function getValidBadgesBySlug(dataSrc: any[], slug: string, validBadges: Badge[]) {
    // Find the game in the array by its slug
    const game = dataSrc.find((game) => game.slug === slug);
  
    if (game?.meta?.badges) {
        const firstMatchingBadge = game.meta.badges.find((badgeLabel: string) => {
            return validBadges.some(badge => badge.label.toLowerCase() === badgeLabel.toLowerCase());
        });

        // If a matching valid badge is found, return an object with label, bgColor and labelColor
        if (firstMatchingBadge) {
            const validBadge = validBadges.find(badge => badge.label.toLowerCase() === firstMatchingBadge.toLowerCase());
            if (validBadge) {
                return {
                    label: validBadge.label,
                    backgroundColor: validBadge.backgroundColor,
                    labelColor: validBadge.labelColor,
                };
            }
        }
    }
  
    // If the game or its badges are not found, return an object with default values
    return { label: '', backgroundColor: '', labelColor: '' };
}
