'use client';
import { LocalStorageService } from '@/shared/utils/local-storage';
import { useSearchParams } from 'next/navigation';
import React, {createContext, ReactNode, useEffect, useRef} from 'react';
import {AITracksDefault, useAnalytics} from "@/features/analytics/AI/AITracks";
import {AnalyticsPageTypes} from "@/app.model";
import {IModularPage} from "@/shared/api/pages";
import {BURGER_INPUT_ID} from "@/components/SideBar";
type TProps = Readonly<{ classnames?: string; children: ReactNode, aiPageType: AnalyticsPageTypes, pageData?: IModularPage }>;

export const AnalyticsContext = createContext<{AITracks: any, AIReadyAsDep: boolean}>({AITracks: AITracksDefault, AIReadyAsDep: false});
const AnalyticsContextProvider = AnalyticsContext.Provider;

const Layout = (props: TProps) => {
    const { classnames, children, aiPageType, pageData } = props;
    const searchParams = useSearchParams();
    const isSentryQp = searchParams.get('evo:sentry_test') === 'true';
    const isSentryLs = LocalStorageService.getItem('sentry_test') === 'true';
    const pageType = aiPageType ?? AnalyticsPageTypes.UNKNOWN;
    const [AITracks, AIReadyAsDep] = useAnalytics(pageData, pageType);

    useEffect(() => {
        AIReadyAsDep && AITracks.PageView();
    }, [AIReadyAsDep]);

    useEffect(() => {
        window.addEventListener('keyup', accessibilityEnterKeyForFocusedHandler);
        function accessibilityEnterKeyForFocusedHandler(ev: Event) {
            if ((ev as any)?.key === 'Enter') {
                const focusedElement: any = ev?.target;
                if (focusedElement?.id === BURGER_INPUT_ID) { // input checkbox, e.g. burger
                    focusedElement.checked = !focusedElement.checked;
                    focusedElement.setAttribute('checked', focusedElement.checked ? 'false' : 'true');
                    focusedElement?.focus?.();
                } else if (focusedElement) { // links, buttons, cards, etc.
                    focusedElement?.click?.();
                }
            }
        }
        return () => {
            window.removeEventListener('keyup', accessibilityEnterKeyForFocusedHandler);
        }
    }, []);

    const handleButtonClick = () => {
        fetch('/api/sentry?throw=true');
        throw new Error('test sentry frontend error ' + Math.random().toFixed(10));
    };
    return (
        <>
            <AnalyticsContextProvider value={{AITracks, AIReadyAsDep}}>
                <div>
                    {(isSentryLs || isSentryQp) && (
                        <button style={{ marginLeft: '50%' }} onClick={handleButtonClick}>
                            test sentry frontend error
                        </button>
                    )}
                    <main className={classnames}>{children}</main>
                </div>
            </AnalyticsContextProvider>
        </>
    );
};

export default Layout;
