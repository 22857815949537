import { TCardPropsCMS } from '../../_core/TypesCardPropsCMS';
import { generateCardId } from '../../helper_funcs/generateCardId';
import { atomCallToActionMarkup, TSTStateCalltoaction } from '../../element_atoms/call-to-action';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';

export type TSTState = TCardPropsCMS | any;

export function renderMarkup(passedState: TSTState = null, _ = {}) {
    const id = generateCardId('ark-ui-block-card-hero-seo');
    const overrideStyling = (selector: string, value: string) =>
            [undefined, null, ''].includes(value) ? '' : `style="${selector}: ${value};"`,
        state: TSTState = passedState,
        {
            title,
            description,
            link_label,
            background_color,
            override_color_title,
            override_color_description,
            override_button_color,
        } = state;
    const btnText: string = state?.link_label || '';
    const btnStateBasic: TSTStateCalltoaction = {
        text: btnText,
        size: 'medium',
        tabIndex: -1,
        addClass: 'link',
        styling: 'text',
        override_button_normal_color: state?.override_button_normal_color || '',
        override_button_normal_background: state?.override_button_normal_background || '',
        override_button_normal_border: state?.override_button_normal_border || '',
        override_button_hover_color: state?.override_button_hover_color || '',
        override_button_hover_background: state?.override_button_hover_background || '',
        override_button_hover_border: state?.override_button_hover_border || '',
    };
    const renderButtons = () => `
        ${atomCallToActionMarkup(btnStateBasic)}
    `;

    return `
    <div id="${id}" class="ark-ui-block-card-hero-seo card" ${overrideStyling(
        'background-color',
        background_color
    )} tabindex="${ETabIndexesOrderingLevels.NOINTERACTION}">
        <p class="title" ${overrideStyling('color', override_color_title)}>${title}</p>
        <p class="description" ${overrideStyling('color', override_color_description)}>${description}</p>
        ${link_label && renderButtons()}
    </div>
    `.replaceAll(/undefined|null/g, '');
}
